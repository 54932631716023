<template>
  <div>
    <vue-element-loading :active="appLoading" spinner="bar-fade-scale" color="black" size="128" is-full-screen />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center"><span style="color: #fff">
            {{ msg }}
          </span></v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="cropImageDialog" persistent :width="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
      ? '100vw'
      : $vuetify.breakpoint.name == 'md'
        ? '80vw'
        : $vuetify.breakpoint.name == 'lg'
          ? '50vw'
          : '40vw'
      ">
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <ImageCropper :image="currentImage" :key="currentImage" @stepper="imageCropper" />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center pt-5 px-3>
      <v-flex xs6>
        <v-layout wrap>
          <v-flex xs12 text-left>
            <span style="color: #000; font-family: poppinsbold; font-size: 25px">Home Details</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs6 text-right>
        <v-dialog :retain-focus="false" v-model="dialog" persistent max-width="1000px" :key="dialog">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark color="green" v-bind="attrs" v-on="on"> UPDATE </v-btn>
          </template>
          <v-card>
            <v-form>
              <v-card-title>
                <span class="headline">Updates Details</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field outlined dense v-model="home.mainTitle" label="Title" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field outlined dense v-model="home.subTitle" label="Sub Title" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <span>Upload images </span>
                      <v-card class="mx-auto" outlined>
                        <v-layout wrap pa-3>
                          <v-flex xs12 md12 lg12>
                            <v-layout wrap>
                              <v-flex lg1> </v-flex>
                              <v-flex xs12>
                                <v-layout wrap justify-center fill-height>
                                  <v-flex xs12 sm6 v-for="(item, i) in home.bannerImage" :key="i" pa-1>
                                    <v-img :src="mediaURL + item">
                                      <v-layout wrap>
                                        <v-flex text-right pa-0>
                                          <v-avatar color="#FF3434" size="15">
                                            <v-icon color="#FFF" small @click="removePhoto(i)">
                                              mdi-close
                                            </v-icon>
                                          </v-avatar>
                                        </v-flex>
                                      </v-layout>
                                    </v-img>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12 sm3 v-for="(item, i) in imageArray" :key="i" pa-1>
                                <v-img :src="item" height="50px" contain>
                                  <v-layout wrap>
                                    <v-flex text-right pa-0>
                                      <v-avatar color="#FF3434" size="15">
                                        <v-icon color="#FFF" small @click="removeImageArray(i)">
                                          mdi-close
                                        </v-icon>
                                      </v-avatar>
                                    </v-flex>
                                  </v-layout>
                                </v-img>
                              </v-flex>
                            </v-layout>

                            <div class="dottedline"></div>
                            <v-layout wrap pt-1 align-center>
                              <v-flex xs10 md6 lg12 align-self-center text-center pb-2>
                                <v-icon size="25px" id="pro_pic" @click="$refs.files.click()">mdi-plus</v-icon>
                                <span style="color: #828282; font-size: 14px">Upload your Image</span>
                                <input v-show="false" accept="image/*" id="file" ref="files" type="file"
                                  @change="uploadImages" />
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-col>
                    <v-col cols="12">
                      <span>Description</span>
                    </v-col>
                    <v-col cols="12">
                      <div id="app">
                        <vue-editor v-model="home.description"></vue-editor>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field outlined dense v-model="home.topContent.title" label="Top Title"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <span>Top Description</span>
                    </v-col>
                    <v-col cols="12">
                      <div id="app">
                        <vue-editor v-model="home.topContent.description"></vue-editor>
                      </div>
                    </v-col>
                    <v-flex py-5 xs12 sm12 px-4>
                      <v-layout wrap justify-center pa-2 py-5 style="
                        border-style: dotted;
                        border-color: black;
                        background-color: white;
                      ">
                        <v-flex xs12>
                          <v-img v-if="home.topContent.image && !imagePreview" :src="mediaURL + home.topContent.image"
                            style="align-center" contain height="200px" />
                          <img v-if="imagePreview" :src="imagePreview" v-show="showPreview" class="align-center" contain
                            height="200px" />
                        </v-flex>
                        <v-flex xs12 pt-2>
                          <v-icon small @click="$refs.showImage.click()" color="black" style="
                              font-family: poppinssemibold;
                              font-size: 17px;
                            ">mdi-plus</v-icon>
                          <span style="
                              font-family: poppinssemibold;
                              font-size: 15px;
                            ">Upload Top Image</span>
                          <input v-show="false" id="file" ref="showImage" multiple type="file" @change="showImage" />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field outlined dense v-model="home.middleContent.title" label="Middle Title"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <span>Middle Description</span>
                    </v-col>
                    <v-col cols="12">
                      <div id="app">
                        <vue-editor v-model="home.middleContent.description"></vue-editor>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <ImageComp :singleImage="home.middleContent.image" @stepper="winStepper"
                        :heading="'Upload Middle Image'" :componentType="'homeMiddleImage'" />
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field outlined dense v-model="home.caroselContent.title" label="Offer Title"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <span>Offer Caption</span>
                    </v-col>
                    <v-col cols="12">
                      <div id="app">
                        <vue-editor v-model="home.caroselContent.caption"></vue-editor>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <span>Offer Description</span>
                    </v-col>
                    <v-col cols="12">
                      <div id="app">
                        <vue-editor v-model="home.caroselContent.description"></vue-editor>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field outlined dense v-model="home.bottomContent.title" label="Bottom Title"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <span>Bottom Description</span>
                    </v-col>
                    <v-col cols="12">
                      <div id="app">
                        <vue-editor v-model="home.bottomContent.description"></vue-editor>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <ImageComp :singleImage="home.bottomContent.image" @stepper="winStepper"
                        :heading="'Upload bottom Image'" :componentType="'homeBottomImage'" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="update()">
                  Save
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-5>
      <v-flex xs12 sm12 md12>
        <v-img src="../../../assets/images/Background.png" height="300px">
          <v-layout wrap justify-center align-center pt-16>
            <v-flex xs12 sm6 md8 pa-2 align-self-center v-if="home" text-center>
              <span style="font-family: poppinsbold; font-size: 18px; color: white">
                {{ home.mainTitle }}
                <br />
                <span style="
                    font-family: poppinsmedium;
                    font-size: 16px;
                    color: white;
                  " v-html="home.subTitle"></span>
                <br /><br />
                <span style="
                    font-family: poppinsregular;
                    font-size: 14px;
                    color: white;
                  " v-html="home.description"></span>
              </span>
            </v-flex>
          </v-layout>
        </v-img>
      </v-flex>
      <!-- <v-flex xs12 sm6 md8 pa-2 align-self-center v-if=" home">
                <span style="font-family: poppinsbold; font-size: 16px">
                    {{ home.mainTitle }}
                    <br />
                    <span style="
                font-family: poppinsmedium;
                font-size: 14px;
                text-align: justify;
              " v-html="home.subTitle
                  "></span>
                    <br /><br>
                    <span style="
                font-family: poppinsregular;
                font-size: 14px;
                text-align: justify;
              " v-html="home.description
                  "></span>
                </span>
            </v-flex> -->
    </v-layout>
    <v-layout wrap justify-center pt-5 pa-5>
      <v-flex xs12 sm12 md4 pa-2 align-self-center v-if="home.topContent">
        <v-img :src="mediaURL + home.topContent.image">
        </v-img>
      </v-flex>
      <v-flex xs12 sm12 md8 pa-2 align-self-center v-if="home.topContent">
        <span style="font-family: poppinsbold; font-size: 16px">
          {{ home.topContent.title }}
          <br />
          <br />
          <span style="
              font-family: poppinsregular;
              font-size: 14px;
              text-align: justify;
            " v-html="home.topContent.description"></span>
        </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-5 pa-5>
      <v-img :src="mediaURL + home.middleContent.image">
        <v-flex xs12 sm12 md8 pa-2 align-self-center v-if="home.middleContent">
          <span style="font-family: poppinsbold; font-size: 16px">
            {{ home.middleContent.title }}
            <br />
            <br />
            <span style="
              font-family: poppinsregular;
              font-size: 14px;
              text-align: justify;
            " v-html="home.middleContent.description"></span>
          </span>
        </v-flex>
      </v-img>
    </v-layout>
    <v-layout wrap justify-center pt-5 pa-5>
      <v-flex xs12 sm12 md12 pa-2 align-self-center v-if="home.caroselContent">
        <span style="font-family: poppinsbold; font-size: 16px">
          {{ home.caroselContent.title }}
          <br />
          <span style="
              font-family: poppinsmedium;
              font-size: 14px;
              text-align: justify;
            " v-html="home.caroselContent.caption"></span>
          <br />
          <br />
          <span style="
              font-family: poppinsregular;
              font-size: 14px;
              text-align: justify;
            " v-html="home.caroselContent.description"></span>
        </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-5 pa-5>
      <v-flex xs12 sm12 md4 pa-2 align-self-center v-if="home.bottomContent">
        <v-img :src="mediaURL + home.bottomContent.image" contain>
        </v-img>
      </v-flex>
      <v-flex xs12 sm12 md8 pa-2 align-self-center v-if="home.bottomContent">
        <span style="font-family: poppinsbold; font-size: 16px">
          {{ home.bottomContent.title }}
          <br />
          <br />
          <span style="
              font-family: poppinsregular;
              font-size: 14px;
              text-align: justify;
            " v-html="home.bottomContent.description"></span>
        </span>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import ImageCropper from "../../../components/Common/imageCropper";
import ImageComp from "@/components/Common/singleImages";
import axios from "axios";
export default {
  components: {
    ImageCropper,
    VueEditor,
    ImageComp,
  },
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      imageArray: [],
      Images: new FormData(),
      formData: new FormData(),
      selectedFiles: null,
      currentImage: "",
      cropImageDialog: false,
      image: null,
      serviceImage: "",
      imagePreview: "",
      showPreview: false,
      file: "",
      dialog: false,
      editdialog: false,
      editingitem: {},
      homeMiddleImage: '',
      homeBottomImage: '',
      appLoading: false,
      dialogDelete: false,
      attachments: null,
      attachmentsformData: new FormData(),
      cId: "",
      home: [],
      // home: {
      //     tittle1: "",
      //     tittle2: "",
      //     description: "",
      // },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    imageCropper(payload) {
      this.cropImageDialog = payload.dialog;
      if (payload.image) {
        this.collectImages(payload.image);
      }
    },
    winStepper(window_data) {
      if (window_data.type == "homeMiddleImage") {
        this.homeMiddleImage = window_data.selectedFiles;
      }
      if (window_data.type == "homeBottomImage") {
        this.homeBottomImage = window_data.selectedFiles;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/home/admin/getDetails",
        method: "get",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.home = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },
    update() {
      var data = {};
      data["mainTitle"] = this.home.mainTitle;
      data["subTitle"] = this.home.subTitle;
      data["description"] = this.home.description;
      data["topContent"] = this.home.topContent;
      data["middleContent"] = this.home.middleContent;
      data["bottomContent"] = this.home.bottomContent;
      data["caroselContent"] = this.home.caroselContent;
      axios({
        url: "/home/edit/details",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (this.imageArray.length > 0) {
              this.uploadAllImages();
            }
            if (this.file) {
              this.uploadTopImage();
            }
            if (this.homeMiddleImage) {
              this.uploadMiddleImage();
            }
            if (this.homeBottomImage) {
              this.uploadBottomImage();
            }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    removeImageArray(i) {
      var values = this.formData.getAll("photos");
      values.splice(i, 1);
      this.formData.set("photos", values);
      this.imageArray.splice(i, 1);
    },
    removePhoto(i) {
      var Data = {};
      this.home.bannerImage.splice(i, 1);
      Data["position"] = i + 1;
      axios({
        method: "POST",
        url: "/home/banner/RemoveImagesingle",
        data: Data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            // this.imageCount =
            //   this.product.photos.length + this.imageArray.length;
            this.showSnackBar = true;
            this.msg = "Deleted Successfully";
          } else {
            this.msg = "Can't Delete Image";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadImages(event) {
      if (this.imageArray.length < 4) {
        this.currentImage = URL.createObjectURL(event.target.files[0]);
        this.cropImageDialog = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },
    collectImages(image) {
      this.selectedFiles = image;
      this.Images.append("image", this.selectedFiles);
      this.formData.append("bannerImage", this.selectedFiles);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.imageArray.push(urls);
        if (this.imageArray) {
          this.image = this.imageArray[0];
        }
      };
      console.log("image", this.selectedFiles);
    },
    uploadAllImages() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/home/upload/banner/image",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Updated Sucessfully";
            this.showsnackbar = true;
          } else {
            this.msg = "Can't Upload Images";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    showImage(e) {
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };
      this.showPreview = true;
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    uploadTopImage() {
      let formData = new FormData();
      formData.append("photo", this.file);

      axios({
        method: "POST",
        url: "/home/upload/topContent/image",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
            this.file = "";
            formData.append = "";
            this.imagePreview = "";
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadMiddleImage() {
      let formData = new FormData();
      formData.append("photo", this.homeMiddleImage);

      axios({
        method: "POST",
        url: "/home/upload/middleContent/image",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadBottomImage() {
      let formData = new FormData();
      formData.append("photo", this.homeBottomImage);
      axios({
        method: "POST",
        url: "/home/upload/bottomContent/image",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>